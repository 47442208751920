
function reCalculate(){
    akontace = 0;
    splatky  = 0;
    price = parseInt($('#price-car').val());

    $( ".akontace a" ).each(function( index ) {
        if($(this).hasClass('active')) akontace = parseInt($(this).attr('data-value'));
    });
    $( ".splatky a" ).each(function( index ) {
        if($(this).hasClass('active')) splatky = parseInt($(this).attr('data-value'));
    });
    $('#form-d-akontace').val(akontace);
    $('#form-d-splatka').val(splatky);

    $('#form-m-akontace').val(akontace);
    $('#form-m-splatka').val(splatky);

    $('.text-akontace').html( numberFormat((price/100)*akontace) +' Kč ');
    if(splatky != 0){



        $('.text-splatky').html( numberFormat( ((price-(price/100)*akontace)*1.27)/splatky  )+' Kč ');
    }else{
        $('.text-splatky').html( '0 Kč ');
    }
    /*
    akontace = parseInt($('.akontace a').hasClass('active').attr('data-value'));
    splatky  = parseInt($('.splatky a').hasClass('active').attr('data-value'));


    */
}
function numberFormat(nStr)
{
        nStr += '';
        x = nStr.split('.');
        x1 = x[0];
        x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ' ' + '$2');
        }
        return x1  + " ";
}

$(document).ready(function() {


    $('.splatky a').click(function(){
        $('.splatky a').removeClass('active');
        $(this).addClass('active');

        reCalculate();
    });

    $('.akontace a').click(function(){
        $('.akontace a').removeClass('active');
        $(this).addClass('active');

        reCalculate();
    });



    $("#slide-1,#slide-2").slider();

    $("#slide-1,#slide-2").on("slide", function(slideEvt) {
    	$('#'+$(this).attr('data-target-text')).text(slideEvt.value);
        price = parseInt($('#price-car').val());

        akontace = parseInt(  (price/100)*$('#slide-1').val()   );
        splatka  = parseInt(  (price - akontace) / $('#slide-2').val()  );


        $('#akontace-text strong').html( akontace+' Kč' );
        $('#splatka-text strong').html( splatka +' Kč' );

    });

    $("#slide-1,#slide-2").change(function(slideEvt) {
    	$('#'+$(this).attr('data-target-text')).text($(this).val());
    });

    $('.quote li').click(function(){
        if($(this).hasClass('active')){
            $(this).removeClass('active');
        }else{
            $('.quote li').removeClass('active');
            $(this).addClass('active');
        }
    });

    $('input').iCheck({
       checkboxClass: 'icheckbox_minimal',
       radioClass: 'iradio_minimal',
       increaseArea: '20%' // optional
     });

     $('input[name="jizda"]').on('ifChecked', function(event){
         $('.hidden-input').removeAttr('disabled');
         $('.hidden-input').attr('required','');
         $('.hidden-input').addClass('active');
     });

     $('input[name="jizda"]').on('ifUnchecked', function(event){
         $('.hidden-input').attr('disabled','disabled');
         $('.hidden-input').removeAttr('required');
         $('.hidden-input').removeClass('active');
     });

     jQuery('.hidden-input').datetimepicker();

    $('.owl-slider').owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        items: 1,
        autoplay:true,
        autoplayTimeout:5000
    });

    $('.owl-photos').owlCarousel({
        loop: false,
        margin: 0,
        nav: true,

        navText:['<i class="icon icon-arrow2-left"></i>','<i class="icon icon-arrow2-right"></i>'],
        responsive : {
            // breakpoint from 0 up
            0 : {
                items: 1
            },
            586 : {
                items: 3
            },
            1100 : {
                items: 4
            },
            1400 : {
                items: 6
            }
        }
    });
    $('.owl-cars').owlCarousel({
        loop: true,
        margin: 25,
        nav: true,
        navText:['<i class="icon icon-arrow2-left"></i>','<i class="icon icon-arrow2-right"></i>'],
        responsive : {
            // breakpoint from 0 up
            0 : {
                items: 1
            },
            590:{
                items: 1
            },
            785 : {
                items: 2
            },
            1000 : {
                items: 3
            },
            1200: {
                items: 4
            }
        }
    });

    $('.photo').magnificPopup({type:'image',gallery:{
          enabled:true
    }});
    $("#head i.navTablet").click(function() {
        if($('#head ul.navigace').hasClass('active')){
            $('#head ul.navigace').removeClass('active');
            //$(this).removeClass('icon-delete').addClass('icon-bars');
        }else{
            $('#head ul.navigace').addClass('active');
            //$(this).addClass('icon-delete').removeClass('icon-bars');
        }
    });
    $( window ).resize(function() {
        if($(window).width() > 990){
            $('#head ul.navigace').removeClass('active');
            //$("#head i.navTablet").removeClass('icon-delete').addClass('icon-bars');
        }
        resizeUlDetail();

        /* FIX hp height of photogalery*/
        $('#map .photo span').css('height',parseInt($('#map .photo img').outerHeight()));
        $('#map .photo span i').css('line-height',parseInt($('#map .photo img').outerHeight())+'px');
        /* END hp height of photogalery*/


        /* FIX photo galery */
        $('.owl-galery .item a span').css('height',parseInt($('.owl-galery img').outerHeight()));
        //$('.owl-galery .item a span i').css('line-height',parseInt($('.owl-galery img').outerHeight())-20+"px");

        $('.owl-galery .owl-nav div').css('height',parseInt($('.owl-galery img').height()));
        $('.owl-galery .owl-nav div').css('line-height',parseInt($('.owl-galery img').height())+"px");
        $('.owl-galery .owl-nav div').css('margin-top',"-"+parseInt($('.owl-galery img').height())+"px");
        /* END hp why block */

        /* FIX google map height */
        $('#googleMap').css('height',parseInt($('#map .photoG').outerHeight()));
        if($( "body" ).has( ".contact-box" ).length == 1){
            $('#googleMap').css('height',parseInt($('.contact-box').innerHeight()));
        }
        /* END google map height*/

    });

    if($('body').has(".photo").length == 1 ){
       $( "#detail-car .photo img" ).ready(function() {
           setTimeout(resizeUlDetail, 1000);
       });
    }


    /* FIX photo galery */
    $('.owl-galery .item a span').css('height',parseInt($('.owl-galery img').outerHeight()));
    //$('.owl-galery .item a span i').css('line-height',parseInt($('.owl-galery img').outerHeight())-20+"px");

    $('.owl-galery .owl-nav div').css('height',parseInt($('.owl-galery img').height()));
    $('.owl-galery .owl-nav div').css('line-height',parseInt($('.owl-galery img').height())+"px");
    $('.owl-galery .owl-nav div').css('margin-top',"-"+parseInt($('.owl-galery img').height())+"px");
    /* END hp why block */

    /* FIX hp height of photogalery*/
    $('#map .photo span').css('height',parseInt($('#map .photo img').outerHeight()));
    $('#map .photo span i').css('line-height',parseInt($('#map .photo img').outerHeight())+'px');
    /* END hp height of photogalery*/



    /* FIX google map height */
    $('#googleMap').css('height',parseInt($('#map .photoG').outerHeight()));

    $(document).scroll(function() {
        //if($( "body" ).has( ".contact-box" ).length == 1){
            $('#googleMap').css('height',parseInt($('.contact-box').innerHeight()));
        //}
    });

    /* END google map height*/

    $('#head li a.dropdown-toggle').click(function(){
        $('#head li.dropdown ul').removeClass('open');

        uls = $(this).parent("li.dropdown").find('ul.dropdown')


        if(uls.hasClass('open')){
            uls.removeClass('open');
        }else{
            uls.addClass('open');
        }
    });

    $('.orderBy a').click(function(e){
        e.preventDefault();
        $('#filter input[name="filtr_razeni"]').attr('value',$(this).attr('data-value'));

        $('#filter button').click();
    });
    $(document).scroll(function() {
        if($(document).scrollTop() >= $('#detail-car').outerHeight()){
            $('#car-scroll').addClass('active');
        }else{
            $('#car-scroll').removeClass('active');
        }
    });


    $("#filter .btn-filter  a.showAdvanceFilter").click(function(){
        if($('#filter .hiddens').hasClass('active')){
            $('#filter .hiddens').removeClass('active');
            $(this).html('Více voleb <i class="icon icon-arrow-right"></i>');
            $('#filter input[name="filtr_zabaleno"]').attr('value','0');
        }else{
            $('#filter .hiddens').addClass('active');
            $(this).html('Méně voleb <i class="icon icon-arrow-right"></i>');
            $('#filter input[name="filtr_zabaleno"]').attr('value','1');
        }
    });

    $('#detail-car .bt a.mobile').click(function(){
        if($('#'+$(this).attr('data-target')).hasClass('active')){
            $('#'+$(this).attr('data-target')).removeClass('active');
            $(this).find('i.icon').removeClass('icon-close-browser').addClass($(this).attr('data-icon'));
            $('#defaultUl').show();
        }else{
            $('#detail-car .list').removeClass('active');
            //alert($(this).attr('data-close-icon'));
            $('#detail-car .bt  i.icon-close-browser').removeClass('icon-close-browser').addClass($(this).attr('data-close-icon'));


            //$('#detail-car .bt a i.icon-close-browser').removeClass('icon-close-browser').addClass($(this).attr('data-close-icon'));

            $('#'+$(this).attr('data-target')).addClass('active');
            $(this).find('i.icon').removeClass($(this).attr('data-icon')).addClass('icon-close-browser');
            $('#defaultUl').hide();

        }
    });

    $('.modal a.close-modal').click(function(){
        $($(this).attr('data-target')).removeClass('active');
    });

    $('#detail-car .bt a.desktop').click(function(){
        $('.modal').removeClass('active').css('height',$(document).height());
        $('#'+$(this).attr('data-target')).addClass('active');
        $('#'+$(this).attr('data-target')+' .modal-content').css('margin-top',($(document).scrollTop()+50));
    });

    $("#share").jsSocials({
    showLabel: false,
    showCount: false,

    shares: [{
        renderer: function() {
            var $result = $("<div>");

            var script = document.createElement("script");
            script.text = "(function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; js = d.createElement(s); js.id = id; js.src = \"//connect.facebook.net/cs_CS/sdk.js#xfbml=1&version=v2.3\"; fjs.parentNode.insertBefore(js, fjs); }(document, 'script', 'facebook-jssdk'));";
            $result.append(script);

            $("<div>").addClass("fb-share-button")
                .attr("data-layout", "button_count")
                .appendTo($result);

            return $result;
        }
    }, {
        renderer: function() {
            var $result = $("<div>");

            var script = document.createElement("script");
            script.src = "https://apis.google.com/js/platform.js";
            $result.append(script);

            $("<div>").addClass("g-plus")
                .attr({
                    "data-action": "share",
                    "data-annotation": "bubble"
                })
                .appendTo($result);

            return $result;
        }
    }]
});
});

$(function() {
  $('select').selectric();

});

function initMap() {
        // Styles a map in night mode.
        var map = new google.maps.Map(document.getElementById('googleMap'), {
          center: {lat: 49.6987082, lng: 13.4261639},
          zoom: 17,
          disableDefaultUI: false,
          scrollwheel: false

        });
        var image = 'https://www.rominocar.cz/public/images/mapmarker.png';
        var myLatlng = new google.maps.LatLng(49.6977082,13.4261639);
        var marker = new google.maps.Marker({
            position: myLatlng,
            animation: google.maps.Animation.DROP,
            title:"Autocentrum Plzeň",
            anchor: new google.maps.Point(28, -85),
            icon: image
        });
        marker.setMap(map);
      }

function resizeUlDetail(){
    if($(window).width() > 1056 && $('#detail-car .photo img').outerHeight()>0){
        v = (parseInt($('#detail-car  .photo img').outerHeight())-150)/6;
        $('#detail-car ul li').css('height',v).css('line-height',(v-20)+'px');
        $('#detail-car #rezervace').css("height",parseInt($('#detail-car  .photo img').outerHeight()-140));
        $('#detail-car #kalkulacka-splatek').css("height",parseInt($('#detail-car  .photo img').outerHeight()-140));
    }else{
        $('#detail-car ul li').css('height','').css('line-height','');
        $('#detail-car #rezervace').css("height",'');
        $('#detail-car #kalkulacka-splatek').css("height",'');

    }

}
